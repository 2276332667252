
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'edit category',
    props: {
        dateFilter: { type: Boolean, default: false },
        id: { type: String, default: 'export' }
    },
    components: {},
    setup(props, context) {
        const locales: any = ref([]);
        const store = useStore();
        const i18n = useI18n();
        const system_locale = ref();
        const modal: any = ref();
        const date_from: any = ref('');
        const date_to: any = ref('');

        const exportModal = () => {
            date_from.value = '';
            date_to.value = '';
            modal.value = new Modal(document.getElementById(props.id));
            modal.value.show();
        };

        context.expose({ exportModal });

        const exportFile = e => {
            const searchData = store.getters.getSearchData;
            const data = {
                type: e == undefined ? '' : e,
                dateFrom: date_from.value == undefined ? '' : date_from.value,
                dateTo: date_to.value == undefined ? '' : date_to.value,
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                locale: system_locale.value
            };

            context.emit('exportFile', data);
        };

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        return {
            locales,
            exportFile,
            date_from,
            date_to,
            system_locale,
            exportModal
        };
    }
});
