
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import { useI18n } from 'vue-i18n';
import { setPageHistory } from '@/core/helpers/toolbar';
import ExportModal from '@/components/modals/ExportModal.vue';

export default defineComponent({
    components: { Input, Select, ExportModal },
    props: {
        filter: { type: Boolean, default: false },
        showDelete: { type: Boolean, default: false },
        dateFilter: { type: Boolean, default: false },
        sites: Object,
        status: Object,
        filterOption: { type: Object },
        selectData: Object,
        categories: Object,
        customer_group: Object,
        filterPlaceholder: {
            type: String,
            default: 'message.SELECT'
        },
        inputPlaceholder: {
            type: String,
            default: 'message.SELECT'
        }
    },
    setup(props, context) {
        const inputValue = ref('');
        const selectValue = ref('');
        const store = useStore();
        const i18n = useI18n();
        const exportComponent = ref();

        const filterData = ref({
            site: '',
            customer_group: '',
            email: '',
            name: '',
            economic_number: '',
            status: '',
            category: '',
            basic_info: '',
            order_id: '',
            date_from: '',
            date_to: ''
        });

        const translate = (msg: string) => {
            if (msg.includes('message.')) {
                const splitKeys = msg.split(' ');
                splitKeys.forEach((item, index, array) => {
                    array[index] = i18n.t(item);
                });
                return splitKeys.join(' ');
            }
            return msg;
        };

        const translateFilters = (filters: Record<any, any>) => {
            const data: Record<any, any> = [];
            filters.forEach(filter => {
                data.push({
                    label: filter.label,
                    value: filter.value
                });
            });
            return data;
        };

        const searchHandler = () => {
            const data = {
                input: inputValue.value ? inputValue.value : '',
                select: filterData.value
            };
            store.dispatch(Actions.SEARCH_HANDLER, {
                data: data
            });
            context.emit('search');
        };

        const reloadListing = () => {
            store.commit('SET_SEARCH_DATA', {});
            setPageHistory({});
            inputValue.value = '';
            selectValue.value = '';
            filterData.value.site = '';
            filterData.value.status = '';
            filterData.value.category = '';
            filterData.value.basic_info = '';
            filterData.value.customer_group = '';
            filterData.value.email = '';
            filterData.value.name = '';
            filterData.value.economic_number = '';
            filterData.value.order_id = '';
            filterData.value.date_from = '';
            filterData.value.date_to = '';
            context.emit('reloadListing');
        };

        const loadCategory = () => {
            context.emit('loadCategory');
        };

        const exportHandler = () => {
            exportComponent.value.exportModal();
        };

        const exportFile = data => {
            context.emit('exportFile', data);
        };

        watchEffect(() => {
            if (props.showDelete == true) {
                reloadListing();
            }
        });

        onMounted(() => {
            const searchedData = store.getters.getPageHistory;
            inputValue.value = searchedData?.search_key;
            filterData.value.site = searchedData?.filter?.site;
            filterData.value.status = searchedData?.filter?.status;
            filterData.value.category = searchedData?.filter?.category;
            filterData.value.economic_number = searchedData?.filter?.economic_number;
            filterData.value.name = searchedData?.filter?.name;
            filterData.value.order_id = searchedData?.filter?.order_id;
            filterData.value.date_from = searchedData?.filter?.date_from;
            filterData.value.date_to = searchedData?.filter?.date_to;
            filterData.value.email = searchedData?.filter?.email;
            filterData.value.customer_group = searchedData?.filter?.customer_group;
            if (searchedData?.filter?.category != undefined || searchedData?.filter?.category != '') {
                loadCategory();
            }
            filterData.value.basic_info = searchedData?.filter?.basic_info;
        });
        return {
            inputValue,
            selectValue,
            translate,
            translateFilters,
            searchHandler,
            reloadListing,
            filterData,
            loadCategory,
            exportHandler,
            exportFile,
            exportComponent
        };
    }
});
